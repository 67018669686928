export default {
    en: {
        general: {
            submitting: "Submitting"
        },
        contactForm: {
            firstName: "First Name",
            fromName: "Name",
            lastName: "Last Name",
            fromEmail: "Email Address",
            email: "Email Address",
            subject: "Subject",
            message: "Message",
            submitted: "Thanks for reaching out!",
            sentInfo: "Your message has been sent. We will go over it and get back in touch with you shortly. Just to recap, here is the info you sent:",
            return: "Return to the form"
        },
        bulkForm: {
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email Address",
            phone: "Phone Number",
            message: "Message",
            submitted: "Thanks for reaching out!",
            sentInfo: "Your message has been sent. We will go over it and get back in touch with you shortly. Just to recap, here is the info you sent:",
            return: "Return to the form",
            submit: "Submit"
        },
        industryForm: {
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email Address",
            phone: "Phone Number",
            message: "Message",
            company: "Company",
            submitted: "Thanks for reaching out!",
            sentInfo: "Your message has been sent. We will go over it and get back in touch with you shortly. Just to recap, here is the info you sent:",
            return: "Return to the form",
            submit: "Submit"
        },
        mailingForm: {
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email Address",
            phone: "Phone Number",
            message: "Message",
            company: "Company",
            submitted: "You've been added to our list. Enjoy your MyHY!",
            submit: "Submit"
        },
        error: {
            invalidFields: "Following fields have an invalid or a missing value:",
            general: "An error happened during submit.",
            generalMessage: "Form sending failed due to technical problems. Try again later.",
            fieldRequired: "{field} is required.",
            fieldInvalid: "{field} is invalid or missing."
        }
    }
};