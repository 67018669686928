import styles from '../css/app.pcss';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import translations from '../resources/translations';
import axios from 'axios';
import VueCookie from 'vue-cookie';
import VScrollLock from 'v-scroll-lock'


// App main
const main = async () => {

    // Import our CSS
    //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');

    Vue.use(VScrollLock);
    Vue.use(VueI18n);
    Vue.use(Vuelidate);
    Vue.use(VueCookie);


    const i18n = new VueI18n({

        locale: 'en',
        fallbackLocale: 'en',
        messages: translations
    })


    // process layout images



    // Create our vue instance

    const vm = new Vue({
        el: "#app",
        i18n,
        delimiters: ['[[', ']]'],
        components: {
            'carousel': () => import(/* webpackChunkName: "carousel" */ 'v-owl-carousel'),
            'contact-form': () => import(/* webpackChunkName: "contact-form" */ '../vue/form/Contactform.vue'),
            'industry-form': () => import(/* webpackChunkName: "industry-form" */ '../vue/form/Industryform.vue'),
            'mailing-form': () => import(/* webpackChunkName: "mailing-form" */ '../vue/form/Mailingform.vue'),
            'tab-panel': () => import(/* webpackChunkName: "tab-panel" */ '../vue/TabPanel.vue'),
        },
        data: {
            menuActive: false,
            footerActive: false,
            csrfTokenValue: '',
            csrfName: '',
            cookieName: '',
            modalActive: false,
        },
        methods: {
            closeModal: function() {
                this.modalActive = false;
            },
        },
        mounted() {
            const self = this;

            function getCsrf() {
                return axios.get('/forms/csrfgetter');
            }

            function getCsrfName() {
                return axios.get('/forms/csrfnamegetter');
            }

            axios.all([getCsrf(), getCsrfName()])
                .then(axios.spread(function (csrf, csrfName) {

                    // Both requests are now complete
                    self.csrfTokenValue = csrf.data.trim();
                    self.csrfName = csrfName.data.trim();
                }));

            self.cookieName = 'myhy' + document.getElementsByTagName("body")[0].getAttribute("data-popup-id");
            var cookieStatus = this.$cookie.get(self.cookieName);
            console.log('cookie name: ' + self.cookieName);
            console.log(cookieStatus);
            if (cookieStatus == null) {
                console.log('no cookie found, setting cookie');
                self.modalActive = true;
                this.$cookie.set(self.cookieName, 'true', 1, 'localhost');
            } else {
                console.log('cookie found');
            }
        },
    });
};
// Execute async function
const initMain = () => {
    // Execute async function
    main().then((value) => {
    })
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initMain); // Document still loading so DomContentLoaded can still fire :)
} else {
    initMain();
    const client = ShopifyBuy.buildClient({
        domain: 'drinkmyhy.myshopify.com',
        storefrontAccessToken: '31ebdc82e8029913f84175428c05483a', // previously apiKey, now deprecated
        appId: '8'
    });

    const shopUi = ShopifyBuy.UI.init(client);


    shopUi.createComponent('cart', {

        'options': {
            'cart': {
                'startOpen': false,
                'popup': false,
                'styles': {
                    'button': {
                        'color': '#ffffff',
                        'background-color': '#0facc8',
                        'font-weight': 'bold',
                        'text-transform': 'uppercase',
                        ':hover': {
                            'background-color': '#f5831f'
                        }
                    }
                }
            },
            'toggle': {
                'styles': {
                    'toggle': {
                        'background-color': '#0facc8',
                        ':hover': {
                            'background-color': '#f5831f'
                        }
                    }

                }
            }
        },
        DOMEvents: {
            'click .shopify-buy__btn--cart-checkout': function(event) {
                console.log('yo!');
                //event.preventDefault();
                //alert('yo!');
            }
        }

    });



    const productList = document.getElementsByClassName('shop-product');
    if (productList.length > 0) {
        const productArray = Array.from(productList);
        productArray.forEach(function (el, index, array) {
            var shopifyItem = el.dataset.shopid;
            shopUi.createComponent('product', {
                id: shopifyItem,
                node: el,
                options: {
                    product: {
                        buttonDestination: 'cart',
                        iframe: false,
                        contents: {
                            img: true,
                            price: true,
                            description: true,
                            title: true,
                            options: true,
                            quantity: true,
                            quantityIncrement: true, // button to increase quantity
                            quantityDecrement: true, // button to decrease quantity
                            quantityInput: true,
                            button: true
                        },
                        order: [
                            'img',
                            'title',
                            'price',
                            'description',
                            'options',
                            'quantity',
                            'button'
                        ],
                        styles: {
                            button: {
                                'color': '#ffffff',
                                'background-color': '#698e3d',
                                'font-weight': 'bold',
                                'text-transform': 'uppercase',
                                ':hover': {
                                    'background-color': '#a4d55d'
                                }
                            },
                        },
                        text: {
                            button: 'ADD TO CART'
                        }

                    }
                },
            });
        });

    } else {
        console.log("no product ui elements in current page");

    }

}
